import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            component: resolve => require(['../pages/Login.vue'], resolve)
        },
        {
            path: '/Home',
            component: resolve => require(['../components/Home.vue'], resolve),
            children: [
                {
                    path: '/Notice',
                    component: resolve => require(['../pages/Notice.vue'], resolve)
                },
                {
                    path: '/Type',
                    component: resolve => require(['../pages/Type.vue'], resolve)
                },
                {
                    path: '/Tag',
                    component: resolve => require(['../pages/Tag.vue'], resolve)
                },
                {
                    path: '/Blog',
                    component: resolve => require(['../pages/Blog.vue'], resolve)
                },
                {
                    path: '/Comment',
                    component: resolve => require(['../pages/Comment.vue'], resolve)
                },
                {
                    path: '/Detail',
                    component: resolve => require(['../pages/Detail.vue'], resolve)
                }
            ]
        },

    ]
})