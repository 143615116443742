import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        HOST: 'http://localhost:8686'
    }
})

export default store;