import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import './assets/css/main.css';
import dayjs from 'dayjs';
import VCharts from 'v-charts';

Vue.use(ElementUI);
Vue.use(VCharts);
Vue.prototype.dayjs = dayjs;
Vue.prototype.$confirm = ElementUI.MessageBox.confirm;
Vue._watchers = Vue.prototype._watchers = [];

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    // 星星
    mounted() {
        this.initializeStarField();
    },
    methods: {
        initializeStarField() {
            const starField = document.createElement('div');
            starField.className = 'star-field';
            document.body.appendChild(starField);

            document.addEventListener('mousemove', (event) => {
                this.handleMouseMove(event, starField);
            });
        },
        handleMouseMove(event, starField) {
            const star = document.createElement('div');
            star.className = 'star-line';
            star.style.top = `${event.clientY - 5}px`;
            star.style.left = `${event.clientX - 5}px`;
            starField.appendChild(star);
            setTimeout(() => {
                star.remove();
            }, 1000);
        }
    }
});
